/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #c83771;
  color: #c83771;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #50162d !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #fff;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 0;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: bold;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #3c1122;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #50162d;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #50162d;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 920px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #fff;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 0;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #fff;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 0;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 0;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #fff;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 0;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #fff;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 0;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 0;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #fff;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 0;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: bold;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 0;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #fff;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 0;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #69394b;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(80, 22, 45, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 18px;
  font-size: 1.8rem;
  width: 100%;
  background-color: transparent;
  color: #50162d;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 760px;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.cb-layout2 .desk.maindesk {
  width: 100%;
  max-width: 100%;
}
#home {
  float: left;
  width: 46px;
  height: 177px;
  overflow: hidden;
  position: relative;
  margin: 12px 0 0 37px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.logo {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}
#head {
  float: left;
  width: 100%;
  margin-top: 60px;
}
.cb-layout1 #head {
  margin-top: 40px;
}
.cb-mobile-access {
  display: none !important;
}
div.unit.cb-mobile-invisible {
  display: block !important;
}
.flag {
  background: #d59f31;
  color: #fff;
}
div.cb-form-sent a {
  text-decoration: none;
  margin-top: 50px;
  display: inline-block;
}
#wrapper {
  float: left;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
}
#header {
  width: 100%;
  z-index: 10;
  background: #d59f31;
  position: fixed;
  top: 0;
  left: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 120px;
  box-shadow: 0 0 5px rgba(80, 22, 45, 0.3);
}
#logobg {
  position: fixed;
  right: 0;
  top: 0;
  width: 120px;
  background: #50162d;
  z-index: 12;
  height: 60px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#find {
  float: right;
  width: 166px;
  margin: 20px 40px 20px 0;
}
#find .find {
  float: right;
  width: 22px;
  height: 20px;
  background: #fff url(/images/find.svg) no-repeat 50% 50%;
  border-radius: 0 3px 3px 0;
}
#searchfield {
  float: left;
  width: 144px;
  height: 20px;
  border: none;
  box-shadow: none;
  border-radius: 3px 0 0 3px;
  line-height: 1;
  min-height: 0;
  padding: 0 0 0 6px;
  font-size: 12px;
}
#services {
  float: right;
  margin: 21px 0;
  margin-right: 45px;
  display: none;
}
#services .meta {
  float: left;
  margin: 0 15px;
  color: #fff;
  font-size: 12px;
}
#services .meta:hover,
#services .meta:focus {
  color: #50162d;
}
#services2 {
  float: left;
  width: 100%;
  margin-top: 22px;
}
#services2 .meta {
  float: left;
  text-transform: uppercase;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-right: 30px;
  color: #fff;
  font-size: 12px;
}
#services2 .meta:hover,
#services2 .meta:focus {
  color: #d59f31;
}
#multimood {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 60px;
  display: none;
}
.cb-layout1 #multimood {
  display: block;
}
#content {
  float: left;
  width: 100%;
}
.cb-layout2 #content,
.cb-layout3 #content,
.cb-layout4 #content,
.cb-layout5 #content {
  margin-top: 60px;
}
#maincontent {
  float: left;
  width: 100%;
}
#basecontent {
  float: left;
  width: 100%;
  background: #eaeaea;
  display: none;
}
.cb-layout1 #basecontent {
  display: block;
}
.jubilee {
  position: absolute;
  left: 360px;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  min-height: 150px;
  max-height: 300px;
  width: auto;
  z-index: 2;
}
@media (max-width: 1599px) {
  .jubilee {
    left: calc((100% - 760px)/4);
  }
}
@media (max-width: 1160px) {
  .jubilee {
    left: 70px;
  }
}
@media (max-width: 639px) {
  .jubilee {
    position: relative;
    float: left;
    width: 100%;
    transform: translateY(0);
    left: 0;
    top: 0;
    background: #55C5A3;
    padding: 30px 0;
  }
}
.jubilee__img {
  height: 100%;
  width: auto;
  display: none;
}
.jubilee__img.jubilee__img--large {
  display: block;
}
@media (max-width: 1599px) {
  .jubilee__img.jubilee__img--large {
    display: none;
  }
}
@media (max-width: 1599px) {
  .jubilee__img.jubilee__img--medium {
    display: block;
  }
}
@media (max-width: 639px) {
  .jubilee__img.jubilee__img--medium {
    display: none;
  }
}
@media (max-width: 639px) {
  .jubilee__img.jubilee__img--small {
    display: block;
    width: 128px;
    margin: 0 auto;
  }
}
#navigationwrapper {
  position: relative;
  z-index: 13;
}
#navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background: #fff;
  z-index: 11;
  overflow: scroll;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
}
.opennavi {
  float: left;
  margin: 17px 0 17px 40px;
  display: block;
  width: 40px;
  height: 26px;
  background: url(/images/opennavi.svg) no-repeat 0 0;
  background-size: 100% auto;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.opennavi:hover,
.opennavi:focus {
  cursor: pointer;
}
.closenavi {
  float: left;
  margin-left: 40px;
  margin-top: 20px;
  display: block;
  width: 40px;
  height: 30px;
  background: url(/images/closenavi.svg) no-repeat 0 0;
  display: none;
}
.closenavi:hover,
.closenavi:focus {
  cursor: pointer;
}
.cb-authenticated .closenavi {
  margin-top: 70px;
}
.naviover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: none;
}
#navigationwrapper.cb-toggle-target-active .naviover {
  display: block;
}
div.sub1 {
  float: left;
  width: 100%;
  display: block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 40px;
  margin-top: 100px;
  margin-bottom: 50px;
}
div.sub1 > .cb-toggle {
  display: none !important;
}
div.sub1 > .item {
  float: left;
  width: 100%;
  display: block;
  border-top: 2px solid #50162d;
  position: relative;
}
div.sub1 > .item.exit {
  border-bottom: 2px solid #50162d;
}
div.sub1 > .item.item-empty > .cb-toggle {
  display: none !important;
}
div.sub1 .menu {
  display: block;
  text-transform: uppercase;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  line-height: 1.25;
  padding: 7px 34px 5px 0;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #50162d;
}
div.sub1 .menu.here {
  color: #d59f31;
}
div.sub1 .cb-toggle {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 34px;
  height: 34px;
  background: url(/images/sub1-open.svg) no-repeat 100% 50%;
  display: none;
}
div.sub1 .cb-toggle:hover,
div.sub1 .cb-toggle:focus {
  cursor: pointer;
}
div.sub1 .cb-toggle.cb-toggle-active {
  background-image: url(/images/sub1-close.svg);
}
div.sub2 {
  float: left;
  width: 100%;
}
div.sub2 .cb-toggle {
  display: none !important;
}
div.sub2 > .item {
  float: left;
  width: 100%;
  border-bottom: 1px solid #50162d;
}
div.sub2 > .item.init {
  border-top: 2px solid #50162d;
}
div.sub2 > .item.exit {
  margin-bottom: 40px;
}
div.sub2 .menu {
  text-transform: none;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #50162d;
}
div.sub2 .menu.here {
  color: #d59f31;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
}
#navigation {
  box-shadow: none;
  left: -320px;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
#navigationwrapper.cb-toggle-target-active #navigation {
  left: 0;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
.closenavi {
  display: block;
}
div.sub2 {
  max-height: 0;
  overflow: hidden;
}
div.sub1 {
  margin-top: 50px;
}
div.sub1 .cb-toggle {
  display: block;
}
div.sub1 > .item.cb-toggle-target-active div.sub2 {
  max-height: 1000px;
  -o-transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  transition: all 1s;
}
#footer {
  float: left;
  width: 100%;
  background: #69394b;
  color: #fff;
  font-size: 12px;
  line-height: 1.66666667;
  letter-spacing: 0.03em;
  margin-top: 28px;
  -webkit-font-smoothing: antialiased;
}
#footer a {
  color: #fff;
  text-decoration: none;
}
#footer a:hover,
#footer a:focus {
  color: #d59f31;
}
.footcontent {
  float: left;
  width: 100%;
}
.footpart {
  float: left;
  width: 240px;
  margin-left: 20px;
  margin-top: 30px;
}
.footpart.footpart--1 {
  margin-left: 0;
}
@media (max-width: 639px) {
  .footpart {
    width: 100%;
    margin-left: 0;
  }
}
.foottitle {
  float: left;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
}
.vcard {
  float: left;
  width: 100%;
}
.openings {
  float: left;
  width: 100%;
}
.footlinks {
  float: left;
  width: 100%;
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.045em;
  margin-top: 85px;
  margin-bottom: 15px;
}
@media (max-width: 639px) {
  .footlinks {
    margin-top: 30px;
  }
}
.copyright {
  float: left;
}
#cmsbox {
  float: left;
}
#cmsbox .meta:before {
  content: '//';
  display: inline-block;
  margin: 0 4px;
  color: #fff !important;
}
@media (max-width: 639px) {
  #cmsbox .meta.auth {
    display: none;
  }
}
#farnorthcontent {
  float: left;
  width: 100%;
}
.c2 #farnorthcontent {
  display: none !important;
}
#news {
  float: left;
  width: 100%;
  font-size: 16px;
  margin-top: 42px;
}
.opennews {
  float: right;
  display: none;
  width: 60px;
  height: 60px;
  background: #ba863f url(/images/opennews.svg) no-repeat 50% 50%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.opennews:hover,
.opennews:focus {
  cursor: pointer;
}
.c2 .opennews {
  display: none !important;
}
.closenews {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: block;
  width: 60px;
  height: 60px;
  background: url(/images/closenews.svg) no-repeat 50% 50%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.closenews:hover,
.closenews:focus {
  cursor: pointer;
}
.newstitle {
  display: block;
  text-transform: uppercase;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #d59f31;
}
.cb-zoom-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 34px;
  height: 34px;
  margin-left: -17px;
  margin-top: -17px;
  background: url(/images/icon-zoom.svg) no-repeat 0 0;
  background-size: 34px auto;
  display: none;
}
div.cb-album .body-mobile .cb-zoom-indicator {
  display: block;
}
div.cb-album .body-mobile img.zoom {
  display: none !important;
}
h1 {
  font-size: 46px;
  line-height: 1.2;
  font-family: "Gotham A", "Gotham B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 200;
  background: url(/images/h1.svg) no-repeat 0 0;
  padding-top: 13px;
}
h1 .edit {
  background: none !important;
}
.cb-layout2 h1 {
  max-width: 760px;
  margin: 0 auto;
}
h2 {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.farnorth h2 {
  padding-top: 12px;
  background: url(/images/h1.svg) no-repeat 0 0;
}
h3 {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
h4 {
  text-transform: uppercase;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #d59f31;
  padding-bottom: 19px;
}
strong {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.loud {
  padding: 0.2em 0.4em;
  background-color: #bfbfbf;
  color: #fff;
  /* redo with @basecolor & guard */
}
.loud > .open {
  color: #e6e6e6;
  /* redo with @basecolor */
}
.loud > .open:hover,
.loud > .open:focus {
  color: #bfbfbf;
}
.pale {
  font-size: 16px;
}
.skew {
  letter-spacing: 0.03em;
  font-style: italic;
  font-family: georgia, serif;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
a {
  color: #50162d;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #d59f31;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
div.list + div.list {
  margin-top: -0.2em !important;
}
div.list .gist {
  list-style-type: none;
  margin-left: 0;
  padding-left: 15px;
  background: url(/images/list.svg) no-repeat 0 0.7em;
}
div.link,
div.load {
  background: none !important;
}
div.link a.open,
div.load a.open,
div.link a.load,
div.load a.load {
  margin-left: 0;
}
div.main {
  margin-top: 5px;
  margin-bottom: 26px;
}
.cb-layout2 div.main {
  margin-top: 0;
}
.cb-layout2 div.main div.unit {
  margin-top: 22px;
  margin-bottom: 14px;
}
.cb-layout2 div.main div.unit.seam {
  padding: 33px 0 26px;
  background: #eaeaea;
}
.cb-layout2 div.main div.unit div.head,
.cb-layout2 div.main div.unit div.body,
.cb-layout2 div.main div.unit div.foot {
  float: none;
  margin: 0 auto;
  *zoom: 1;
  max-width: 100%;
}
.cb-layout2 div.main div.unit div.head:before,
.cb-layout2 div.main div.unit div.body:before,
.cb-layout2 div.main div.unit div.foot:before,
.cb-layout2 div.main div.unit div.head:after,
.cb-layout2 div.main div.unit div.body:after,
.cb-layout2 div.main div.unit div.foot:after {
  display: table;
  content: '';
}
.cb-layout2 div.main div.unit div.head:after,
.cb-layout2 div.main div.unit div.body:after,
.cb-layout2 div.main div.unit div.foot:after {
  clear: both;
}
.cb-layout3 div.main {
  margin-top: 13px;
}
.cb-layout3 div.main div.unit.ebgb-hide-part div.pict {
  display: none !important;
}
.cb-layout3 div.main div.unit.ebgb-hide-part div.text p.norm {
  background: #d59f31 !important;
  color: #fff;
}
.cb-layout3 div.main div.unit.ebgb-hide-part:nth-child(2n+1) div.text p.norm {
  background: #50162d !important;
  color: #fff;
}
.cb-layout3 div.main div.unit.seam {
  text-align: center;
}
.cb-layout3 div.main div.unit.seam div.part {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout3 div.main div.unit.seam div.text p.norm {
  background: #eaeaea;
  text-transform: uppercase;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  padding: 24px 10px;
}
.cb-layout3 div.main div.unit.seam div.link {
  background: none !important;
}
.cb-layout3 div.main div.unit.seam div.link a.open {
  margin-left: 0;
}
#view.cb-layout3 div.main div.unit.seam div.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
#view.cb-layout3 div.main div.unit.seam div.link a.open {
  height: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.cb-layout4 div.main {
  margin-top: 33px;
}
.cb-layout5 div.main {
  margin-top: 21px;
}
.cb-layout5 div.main div.unit {
  border-top: 2px solid #50162d;
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout5 div.main div.unit div.part {
  margin-top: 20px;
  margin-bottom: 20px;
}
.cb-layout5 div.main div.unit div.lead + div.text {
  margin-top: 0;
}
.cb-layout5 div.main div.unit div.cb-filter {
  margin-top: 4px;
  margin-bottom: 4px;
}
div.base {
  color: #fff;
  text-align: center;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
}
div.base:empty {
  margin: 0;
}
div.base a {
  color: #fff;
}
div.base div.unit {
  background: #50162d;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 32px 0 33px;
}
div.base div.unit.seam {
  background: #d59f31;
}
div.base div.link {
  background: none !important;
}
div.base div.link a.open {
  margin-left: 0;
}
div.base div.pict img {
  margin: 0 auto;
}
#view div.base div.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  margin: 0;
}
#view div.base div.link a.open {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}
div.farnorth div.part:first-child {
  margin-top: 0;
}
div.farnorth div.edge h2 {
  background: none;
  padding: 0 0 20px;
  text-transform: uppercase;
}
div.north {
  margin-top: 25px;
}
.cb-layout3 div.north {
  margin-bottom: -8px;
}
#edit div.north {
  border-bottom: 3px dashed #ccc;
}
div.north:empty {
  margin: 0 !important;
}
div.north div.unit {
  margin-top: 15px;
  margin-bottom: 15px;
}
div.form {
  font-size: 16px;
}
div.form input.text,
div.form textarea {
  border: none;
  border-radius: 0;
  box-shadow: none;
  min-height: 25px;
  background: #eaeaea;
  padding: 0 6px;
  color: #50162d;
}
div.form div.part {
  margin-top: 4px;
  margin-bottom: 4px;
}
div.form input.submit {
  background: #d59f31;
  min-height: 36px;
  line-height: 1.2;
  padding: 0 24px;
  border: none;
  color: #fff;
  font-size: 18px;
  text-shadow: none;
  border-radius: 0;
  margin-top: 46px;
  -webkit-appearance: none;
}
div.form input.submit:hover,
div.form input.submit:focus {
  background: #50162d;
}
div.form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  min-height: 25px;
  line-height: 25px;
  padding: 0 6px;
  color: #50162d;
  background: #eaeaea url(/images/select.svg) no-repeat 100% 50%;
}
div.fold,
div.flat {
  margin-top: 14px;
  margin-bottom: 26px;
  border-top: 2px solid #50162d;
  border-bottom: 2px solid #50162d;
  -o-transition: background 0.218s;
  -moz-transition: background 0.218s;
  -webkit-transition: background 0.218s;
  transition: background 0.218s;
}
div.fold:hover,
div.flat:hover {
  background: #eaeaea;
}
div.fold + div.fold,
div.flat + div.fold,
div.fold + div.flat,
div.flat + div.flat {
  margin-top: -28px;
}
div.fold div.head h2,
div.flat div.head h2 {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 20px;
  min-height: 81px;
}
div.fold div.more,
div.flat div.more {
  float: left;
  width: 100%;
  padding-bottom: 33px;
}
#view div.fold a.link,
#view div.flat a.link {
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 2;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(/images/fold-open.svg) no-repeat 100% 100%;
}
#view div.fold a.link.more,
#view div.flat a.link.more {
  height: 110px;
}
#view div.fold a.link.less,
#view div.flat a.link.less {
  bottom: 0;
  width: 56px;
  height: 33px;
  background-image: url(/images/fold-close.svg);
}
#view div.fold div.load a.load,
#view div.flat div.load a.load {
  display: block;
  width: 26px;
  height: 28px;
  background: url(/images/fold-load.svg) no-repeat 0 0;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
div.cb-slideshow div.head {
  position: static;
}
div.cb-slideshow div.head h2 {
  padding: 0;
  min-height: 0;
}
div.cb-slideshow div.head div.ctrl {
  z-index: 2;
  top: 50%;
  width: 100%;
  right: 0;
  height: 0;
}
div.cb-slideshow div.head div.ctrl div.prev {
  float: left;
  margin-top: -20px;
}
div.cb-slideshow div.head div.ctrl div.next {
  float: right;
  margin-top: -20px;
}
div.cb-slideshow .body-non-mobile a.foto,
div.cb-slideshow .body-non-mobile span.foto {
  top: 50%;
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
div.cb-slideshow .prev > .cb-hybrid,
div.cb-slideshow .next > .cb-hybrid {
  background-image: url(/images/album-arrows-light.svg);
}
div.cb-strips .cb-strips-images {
  height: 100% !important;
}
div.cb-strips .cb-strips-images img {
  top: 50%;
  position: relative;
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
div.cb-strips .cb-strips-images img.zoom {
  position: absolute;
  -o-transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  transform: none;
}
div.cb-strips div.scan .cb-strips-thumbs {
  width: 90%;
}
div.cb-strips div.scan div.next {
  float: right;
}
div.cb-strips .body-non-mobile > .part.foto .cb-strips-images a.foto {
  pointer-events: none;
}
div.cb-strips .body-non-mobile > .part.foto .cb-strips-images a.foto.is-active {
  pointer-events: auto;
}
div.cb-matrix .body-non-mobile div.scan table {
  display: block;
}
div.cb-matrix .body-non-mobile div.scan table tbody {
  display: block;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr {
  display: block;
  font-size: 0;
  line-height: 0;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr td {
  display: inline-block;
  margin: 0 10px;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr td span,
div.cb-matrix .body-non-mobile div.scan table tbody tr td a.cb-hybrid {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr td span.same,
div.cb-matrix .body-non-mobile div.scan table tbody tr td a.cb-hybrid.same {
  line-height: 20px;
  padding: 0;
  background: none;
  border-bottom: 2px solid #50162d;
  color: #50162d;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr td.prev {
  width: 9px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(/images/matrix-prev-light.svg) no-repeat 0 0;
  margin-bottom: -2px;
  margin-right: 30px;
  margin-left: 0;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr td.prev a {
  display: block;
  width: 9px;
  height: 16px;
  background: url(/images/matrix-prev.svg) no-repeat 0 0;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr td.next {
  width: 9px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(/images/matrix-next-light.svg) no-repeat 0 0;
  margin-bottom: -2px;
  margin-right: 0;
  margin-left: 30px;
}
div.cb-matrix .body-non-mobile div.scan table tbody tr td.next a {
  display: block;
  width: 9px;
  height: 16px;
  background: url(/images/matrix-next.svg) no-repeat 0 0;
}
.grow {
  text-transform: none !important;
}
/* MOOD CONTAINER */
#expo {
  float: left;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 32.73684211%;
}
.cb-layout4 #expo {
  padding-bottom: 50%;
  margin-top: 30px;
}
/* IMAGE CONTAINER */
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  width: 100%;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#expo div.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 2;
}
#expo div.link .cb-index-some {
  display: none;
}
#expo div.link a.link {
  position: absolute;
  top: 50%;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 17px;
  height: 40px;
  margin-top: -20px;
}
#expo div.link a.link.prev {
  left: 40px;
  background: url(/images/mood-prev.svg) no-repeat 0 0;
}
.cb-layout4 #expo div.link a.link.prev {
  left: 20px;
}
#expo div.link a.link.next {
  right: 40px;
  background: url(/images/mood-next.svg) no-repeat 0 0;
}
.cb-layout4 #expo div.link a.link.next {
  right: 20px;
}
#expo div.link .cb-index-all {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  line-height: 0;
  font-size: 0;
  text-align: center;
}
.cb-layout4 #expo div.link .cb-index-all {
  display: none !important;
}
#expo div.link .cb-index-all td {
  display: inline-block;
  margin: 0 7px;
}
#expo div.link .cb-index-all td a {
  display: block;
  width: 10px;
  height: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(/images/mood-index.svg) no-repeat 0 0;
}
#expo div.link .cb-index-all td.this a {
  background-image: url(/images/mood-index-active.svg);
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 3px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1125em 3px;
  min-height: 1.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 16px;
  font-size: 1.6rem;
  color: #444;
  font-weight: normal;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.1125em 1em;
  min-height: 1.2em;
  border: 1px solid;
  border-color: #3c1122 #14060b #14060b #3c1122;
  border-radius: 2px;
  background-color: #50162d;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
  line-height: 1.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #3c1122 #14060b #14060b #3c1122;
  background-color: #5c1934;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #3c1122 #14060b #14060b #3c1122;
  background-color: #280b17;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/******* layout-small.less 2013-1-16 *******/
.desk {
  width: 100%;
  max-width: 81.25%;
}
#home {
  width: 30px;
  height: 24px;
  margin-top: 8px;
  margin-left: 24px;
}
#head {
  margin-top: 40px;
}
#header {
  padding-right: 0;
}
#multimood {
  margin-top: 40px;
}
#multimood div.link {
  display: none;
}
.cb-layout2 #content,
.cb-layout3 #content,
.cb-layout4 #content,
.cb-layout5 #content {
  margin-top: 40px;
}
.opennavi {
  width: 30px;
  height: 20px;
  margin: 10px 0 10px 30px;
}
#logobg {
  width: 78px;
  height: 40px;
  z-index: 11;
}
#find {
  display: none;
}
.cb-layout2 h1 {
  max-width: 81.25%;
}
@media only screen and (max-width: 639px) {
  div.form input.text,
  div.form textarea {
    min-height: 30px;
  }
  #services2 .meta {
    display: block;
    margin: 0;
    float: none;
  }
  div.fold div.head h2,
  div.flat div.head h2 {
    padding-bottom: 40px;
  }
  #view div.fold a.link.more,
  #view div.flat a.link.more {
    width: 56px;
    height: 33px;
    bottom: 0;
  }
}
@media only screen and (max-width: 919px) {
  #farnorthcontent {
    display: none;
  }
  .cb-layout1 #farnorthcontent {
    display: block;
  }
}
@media only screen and (max-width: 639px) {
  h1 {
    font-size: 33px;
    padding-top: 15px;
  }
}
div.cb-slideshow div.head div.ctrl div.prev {
  margin-left: 5%;
}
div.cb-slideshow div.head div.ctrl div.next {
  margin-right: 5%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
.cb-layout2 div.main {
  width: 100%;
  margin-left: 0;
}
.cb-layout2 div.main div.unit {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout2 div.main div.unit div.head,
.cb-layout2 div.main div.unit div.body,
.cb-layout2 div.main div.unit div.foot {
  width: 81.25%;
}
.cb-layout2 div.main div.unit div.part,
.cb-layout2 div.main div.unit table,
.cb-layout2 div.main div.unit div.head h2 {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout2 div.main div.unit div.part {
  width: 100%;
}
div.base div.unit div.head h2,
div.base div.unit div.part {
  margin-left: 4%;
  margin-right: 4%;
}
div.base div.unit div.part {
  width: 92%;
}
.cb-layout5 div.main div.unit div.pict {
  margin-bottom: 0;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan.less 2013-1-16 */
.scan .cb-hybrid {
  text-decoration: none;
}
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
}
.swap td > span,
.swap td > .cb-hybrid {
  display: block;
}
td.prev,
td.next {
  color: rgba(80, 22, 45, 0.3);
}
.unit.cb-batch .same {
  background-color: #280b17;
  color: #50162d;
}
/******* module-album.less 2013-1-16 *******/
.body-mobile img,
noscript img {
  /* resize - noscript mode must still work */
  width: 100%;
}
img.same {
  background: none !important;
}
div.foto div.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.cb-mobile .cb-slideshow > .head > .ctrl {
  display: none;
}
.cb-slideshow > .body-mobile img.zoom {
  top: 100% !important;
  left: 100% !important;
  display: block !important;
  margin: -20px 0 0 -20px !important;
  width: 17px !important;
  height: 17px;
  opacity: 0.7;
}
div.foto,
.crossslideContainer {
  position: relative;
}
.crossslide,
.cb-slideshow-images img {
  width: 100%;
}
.cb-strips .foto > .cb-landscape,
.cb-matrix .body-mobile .foto > img,
.cb-contentflow .foto > img,
.cb-slideshow .foto > img,
.body-non-mobile .foto > img {
  margin: 0 auto;
  width: 100%;
}
.cb-strips .foto > .cb-portrait {
  height: 100%;
  width: auto;
}
.body-mobile a.foto,
.body-mobile span.foto {
  width: 100%;
}
noscript a.foto {
  position: relative !important;
}
.body-mobile .cb-matrix .foto,
.body-mobile .cb-contentflow .foto {
  position: relative;
}
.part.desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.same.norm,
.same.foto {
  background: none;
}
.body-non-mobile a.foto,
.body-non-mobile span.foto {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 0;
  width: 100%;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  width: 18px;
}
.prev > .prev,
.next > .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  display: block;
  height: 40px;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  background-image: url('/images/album-arrows-dark.svg');
  background-repeat: no-repeat;
}
.cb-slideshow .ctrl > .prev,
.cb-slideshow .ctrl > .next {
  float: left;
}
.cb-album .prev {
  background-position: 0px -120px;
}
.cb-strips td.prev,
.cb-strips td.next {
  background: none;
  vertical-align: middle;
}
.cb-album a.prev,
.cb-album .prev > .cb-hybrid {
  background-position: 0px 0px;
}
.cb-album a.prev:hover,
.cb-album .prev > .cb-hybrid:hover {
  background-position: 0px -40px;
}
.cb-album a.prev:active,
.cb-album .prev > .cb-hybrid:active {
  background-position: 0px -80px;
}
.cb-album .prev > .cb-hybrid.fade {
  background-position: 0px -120px;
}
.cb-album .next {
  background-position: -18px -120px;
}
.cb-album a.next,
.cb-album .next > .cb-hybrid {
  background-position: -18px 0px;
}
.cb-album a.next:hover,
.cb-album .next > .cb-hybrid:hover {
  background-position: -18px -40px;
}
.cb-album a.next:active,
.cb-album .next > .cb-hybrid:active {
  background-position: -18px -80px;
}
.cb-album .next > .cb-hybrid.fade {
  background-position: -18px -120px;
}
.cb-strips table {
  border-collapse: collapse;
}
td > .body {
  display: inline-block;
  vertical-align: middle;
}
.cb-strips-images {
  height: 100%;
  width: 100%;
}
.cb-strips-images > .foto {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cb-strips-images > .foto > .cb-landscape {
  width: 100%;
  height: auto;
}
.cb-strips-images > .foto > .cb-portrait {
  width: auto;
  height: 100%;
}
.cb-strips-container > .body > .foto {
  height: 100% !important;
  overflow: hidden;
}
.cb-strips-thumbs a.foto {
  width: 100%;
}
.cb-strips-container > .body a.foto,
.cb-strips-thumbs .part > .foto > .foto {
  position: static;
  width: 100% !important;
  height: 100% !important;
}
.cb-strips-container .cb-landscape,
.cb-strips-container .cb-portrait {
  max-width: 100% !important;
  max-height: 100% !important;
}
.cb-strips-4 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 23% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 14.66666667% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 10.5% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 8% !important;
}
.cb-matrix .body-non-mobile > .foto {
  margin: 1% !important;
}
.cb-matrix .body-non-mobile a.foto {
  position: static;
  height: 100% !important;
}
.cb-matrix-columns-1 .body-non-mobile > .foto {
  width: 98% !important;
}
.cb-matrix-columns-3 .body-non-mobile > .foto,
.cb-matrix-columns-5 .body-non-mobile > .foto,
.cb-matrix-columns-6 .body-non-mobile > .foto,
.cb-matrix-columns-7 .body-non-mobile > .foto,
.cb-matrix-columns-8 .body-non-mobile > .foto {
  width: 48% !important;
}
@media only screen and (min-width: 240px) {
  .cb-matrix-columns-2 .body-non-mobile > .foto,
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 48% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 14.66666667% !important;
  }
}
@media only screen and (min-width: 640px) {
  .cb-matrix-columns-3 .body-non-mobile > .foto {
    width: 31.33333333% !important;
  }
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 23% !important;
  }
  .cb-matrix-columns-5 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 18% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto {
    width: 14.66666667% !important;
  }
}
@media only screen and (min-width: 920px) {
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 12.28571429% !important;
  }
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 10.5% !important;
  }
}
.cb-contentflow .body-non-mobile .part {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}
.foto > .head {
  position: relative;
}
.cb-manual .desc,
.body-non-mobile > .desc {
  position: relative;
}
#view .cb-manual .desc .norm,
#view .body-non-mobile > .desc .norm {
  position: absolute;
  top: 0;
  left: 0;
}
.foto > .head > .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
}
.cb-album .ctrl {
  float: right;
}
#root div.foto div.body div.desc {
  float: left;
}
#root div.foto div.body div.desc div.part {
  float: none;
}
/******* module-filter.less 2013-1-16 *******/
.cb-count {
  padding: 0 !important;
}
.scan th {
  text-align: center;
  white-space: nowrap;
}
.tabs td,
.time td,
.beta td {
  text-align: center;
}
.mese table {
  table-layout: fixed;
}
.mese td,
.mese tbody th {
  text-align: right;
}
.mese td > .cb-hybrid {
  display: block;
}
.cats span {
  padding: 0;
}
.scan > p {
  font-size: 0;
  text-align: center;
}
.scan > p > span {
  padding: 0;
}
.scan > p > .same,
.scan > p > span > .cb-hybrid {
  padding: 0 0.3em;
}
p > span > .cb-hybrid {
  display: inline-block;
}
p > .same,
p > span > .same {
  display: inline-block;
  background: none !important;
}
/******* module-filter-small.less 2013-1-16 *******/
.cb-mobile-filter {
  font-size: 18px;
  font-size: 1.8rem;
  z-index: 20;
  display: inline-block;
  width: 100%;
  background: #50162d;
  color: #555;
}
.cb-mobile-filter-link {
  width: inherit;
}
.cb-icon-filter:before {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  font-family: 'cbfont';
  line-height: 1em;
  speak: none;
  padding: 0 0.2em 0 0.6em;
  width: 1.2em;
  content: '\f0c9';
}
.cb-mobile-filter-items {
  padding-bottom: 1em;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
.cb-mobile-filter-items .cb-hybrid {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  color: #fff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.4);
}
.cb-mobile-filter-items .cb-hybrid:hover,
.cb-mobile-filter-items .cb-hybrid:focus,
.cb-mobile-filter-items .cb-hybrid:active {
  background: #3c1122;
  color: #ffffff;
  text-decoration: none;
}
.cb-mobile-filter-items .mese td > span,
.cb-mobile-filter-items li > span {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.cb-mobile-filter-items > .cb-filter {
  margin: 0 !important;
  width: 100% !important;
}
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding-right: 2%;
  padding-left: 2%;
}
.cb-mobile-filter-items li {
  display: inline-block;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid {
  position: relative;
  overflow: hidden;
  padding-right: 4%;
  padding-left: 4%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 4em;
  height: 100%;
  /*background-color: #1e1e1e;*/
  background-image: -webkit-gradient(linear, left center, right center, from(rgba(30, 30, 30, 0)), to(#1e1e1e));
  background-image: -webkit-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -moz-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -o-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -ms-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  content: '';
}
.mese tbody th,
.mese td > .cb-hybrid,
.mese td > span {
  padding-right: 0.4em;
  padding-left: 0.4em;
}
.cb-mobile-filter-items > .beta {
  text-align: center;
}
.cb-mobile-filter-items > .beta > span {
  float: left;
  padding: 0;
  width: 20%;
  line-height: 3em;
}
.cb-mobile-filter-items > .beta > span > .cb-hybrid {
  padding: 0;
}
.cb-mobile-filter-items > .scan > p > span > .cb-hybrid {
  display: inline-block;
  overflow: hidden;
  max-width: 9em;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.font9 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font12 {
  font-size: 16px;
  font-size: 1.6rem;
}
.font15 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font18 {
  font-size: 25px;
  font-size: 2.5rem;
}
.font21 {
  font-size: 29px;
  font-size: 2.9rem;
}
.font24 {
  font-size: 33px;
  font-size: 3.3rem;
}
.font27 {
  font-size: 37px;
  font-size: 3.7rem;
}
.font30 {
  font-size: 42px;
  font-size: 4.2rem;
}
.font32 {
  font-size: 44px;
  font-size: 4.4rem;
}
.cb-mobile-filter-items .cb-count,
.cb-mobile-filter-items .days td > span,
.cb-mobile-filter-items .week td > span,
.cb-mobile-filter-items .year td > span {
  display: none;
}
.cb-mobile-filter-items .same > span:first-child {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.keys input {
  display: table;
  margin: 0.3em auto;
  width: 86%;
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.keys input + .cb-hybrid {
  display: none;
}
.scan select {
  margin: 0.4em 2%;
  width: 96%;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #3c1122;
  color: #fff;
  text-decoration: none;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
#cb-cookie-warning {
  position: fixed;
  bottom: 36px;
  z-index: 10000;
  width: 500px;
  max-width: 100%;
  background-color: #fff;
  color: #000;
  font-family: 'helvetica neue', helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.6;
  box-sizing: border-box;
  padding: 30px 40px;
  right: 0;
  transform: translateX(100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  right: 0 !important;
  transform: translateX(100%) !important;
  transition: all 0.3s !important;
}
.show-content #cb-cookie-warning {
  right: 36px;
  transform: translateX(0);
  transition: all 0.6s 0.2s ease;
}
@media (max-width: 639px) {
  .show-content #cb-cookie-warning {
    right: 0;
  }
}
@media (max-width: 639px) {
  #cb-cookie-warning {
    bottom: 0;
    padding: 24px;
    border-radius: 0;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  color: #000;
  font-size: 18px;
  line-height: 1.33333333;
}
.cb-cookie-warning--text a {
  text-decoration: underline;
}
.cb-cookie-warning--text a:hover {
  color: #50162d;
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
}
#cb-cookie-warning__button--accept {
  float: left;
  order: 1;
  font-family: 'helvetica neue', helvetica, sans-serif;
  color: #000;
  text-decoration: underline;
  text-underline-position: under;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.4s ease;
}
#cb-cookie-warning__button--accept:hover {
  color: #d59f31;
}
#cb-cookie-warning__button--decline {
  float: left;
  order: 2;
  font-family: 'helvetica neue', helvetica, sans-serif;
  color: #000;
  text-decoration: underline;
  text-underline-position: under;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: bold;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.4s ease;
}
#cb-cookie-warning__button--decline:hover {
  color: #d59f31;
}
/*# sourceMappingURL=./screen-small.css.map */